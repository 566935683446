/* .container-height {
    display: flex;
    flex-direction: column;
    height: 80vh;

} */
  .horizontal-form-item {
    display: flex; 
  }
  
  .horizontal-form-field {
    flex: 1; 
    margin-right: 20px; 
  }
  
  
  .update-provider-header {
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  
  .update-provider-content {
    flex-grow: 1;
    border: 1px solid #cccccc7c;
    border-radius: 5px;
    padding: 10px;
  }
  
  .update-provider-footer {
    text-align: center;
    padding-top: 5px;
  }
  .ant-picker{
    /* width:100% !important; */
  }
  .providerBack {
    position: absolute;
    top: 10px; /* Adjust the top position as needed */
    right: 10px; /* Adjust the right position as needed */
  }
  
.button-footer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding-top: 10px;
}
