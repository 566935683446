.mfa-input{
    height: 40px !important;
}
.otpVerifyButton{
    margin-top: 4% !important;
}

.divOtp {
    height: 100vh; /* Make the divOtp fill the entire viewport height */
    background-color: #f7f7f7;
  }

  /* Hide arrow buttons for input type number */
/* input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
  font-weight: 600 !important;
}