.foforgotuser {
    text-align: right;
    padding-left: 10%;
}

.custom-label .ant-form-item-label {
    color: #666;
    font-weight: 700;
    padding-bottom: 0px;
}

.link {
    display: flex;
    justify-content: space-between;
    width: 100%;

}

.go-back {
    order: 1;

}

.foforgotuser {
    order: 2;

}

.crdresetheader {
    width: 100%;
    align-items: center;
    text-align: center;
}


.input-with-link {
    text-align: right;
}

.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Adjust the height of the container as needed */
}

.crdheader {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.crdforgotpassword {
    width: 100%;
    max-width: 400px;
    border-radius: 5px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 0px;
}

.logo {
    width: 250px;
    /* Adjust the width of the logo as needed */
    height: auto;
    /* Maintain aspect ratio */
}

.lblusername {
    margin-top: 20px;
    /* Adjust the top margin as needed */
}


/*Mobile view */
@media (max-width: 640px) {

    .lblusername {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }
}

@media (min-width: 641px) and (max-width: 1024px) {
    .lblusername {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .ant-form-item {
        width: 100%;
    }

    .ant-form-item-control-input {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-form-item-control-input-content {
        width: 100%;
        text-align: left;
        /* Center the text content horizontally */
    }

    .link a.go-back {
        margin-left: 180px;
    }

    .link a.foforgotuser {
        margin-right: 160px;
    }
}