.customTable {
    margin-top: 30px;
  }
  .ant-modal-content {
    overflow-y: auto !important;
    /* max-height: calc(125vh - 200px) !important; */
}
.ant-modal {
  top:50px;
}
.providerGrid{
  padding-top: 30px !important;
}