.divLoginContent {
  
  width: 560px;
  min-height: 100vh;
  display: block;
  background-color: #f7f7f7;
  padding: 173px 140px 55px 140px;
}

.divSideImage {
  background-color: #e3e3e3;
  opacity: 0.9;
  background-image: url(../images/sideImage.png);
  background-size: cover;
  background-position: center;

}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 20px; */
}

.heading {
  text-align: center;
  margin-bottom: 20px;
}

#signin {
  display: block;
  margin: 0 auto;
}

.divForgotPassword {
  justify-content: end !important;
}

.login-alert {
  margin-bottom: 10px;
  min-width: 350px;
  text-align: center;
}

/* position: 'absolute', zIndex: '9999',marginTop:'-68px',left:'0' */
.alertLogin {
  position: absolute;
  z-index: 9999;
  margin-top: -68px;
  left: 0;
  margin-left: 90px;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-alert-with-description {
  /* align-items: flex-start; */
  padding: 20px 24px !important;
}

/*Mobile view */
@media (max-width: 640px) {
  .divSideImage {
    display: none !important;
  }

  .divLoginContent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    /* Ensures the width remains unchanged */
    max-width: 100%;
    /* Set maximum width as per your requirement */
    padding: 270px 55px 55px 105px;

  }

  /* .ant-input-affix-wrapper {
    width: 200px
  } */

  .divForgotPassword {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

/*Tab view */
@media (min-width: 641px) and (max-width: 1024px) {
  /* Styles for iPads */

  /* .divSideImage {
      display: none !important;
    } */
  .divLoginContent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    /* Ensures the width remains unchanged */
    max-width: 100%;
    padding: 350px 55px 55px 55px;
  }

  /* .ant-input-affix-wrapper {
    width: 50%;
  } */
 .divForgotPassword {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
 }
}