/* #card {
    margin: 20px;
} */

#submit {
    float: right;
}

/* #securitypin {
    width: 50%;
} */

/* .ant-form-item {
    padding-bottom: 8px;
} */


.example {
  margin: 12px;
}

/* Force update ant style */

/*.ant-input {
  padding: 16px 12px 4px 11px;
}

.ant-select .ant-select-selector {
  padding: 16px 10px 4px 11px;
} */

/* .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 16px 10px 4px 11px;
  height: 48px;
} */

.ant-select-single .ant-select-selector .ant-select-selection-search {
  top: 16px;
}
.alertRegister{
  /* margin-top: 5px !important; */
  
}
.background-container-color{
  padding-top:6px !important;
}
.switch-active {
  background-color: green !important;
}

.switch-inactive {
  background-color: red !important;
}

.buttonRight{
padding: 2% !important;
text-align: end  !important;
}
.buttonLeft{
  padding: 2% !important;
}
.buttonBG{
  background-color: #ffffff !important;
  /* margin-top:55% !important; */
}
/* .registerBottomSpacing{
  margin-bottom: 100px!important;
} */

