.custom-overlay {
  width: 400px;
  /* overflow-x: auto; */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.175);
}

.ant-popover-inner {
  max-height: 'calc(200vh - 100px)' !important;
  overflow-y: 'auto' !important;
}

.location-dropdown {
  max-height: 'calc(100vh - 100px)';
  overflow-Y: 'auto';

}

.ant-popover-close-x {
  top: 0;
  right: 0;
}

.custom-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-button {
  color: black;
  font-weight: bold;
  text-decoration: none;
  border: none;
}

.custom-button:hover {
  color: rgba(0, 0, 0, 0.88);
  background-color: transparent;
  border-radius: 4px;
}

.appointment-container {
  border-radius: 10px;
}

.dropdown-message {
  padding: 10px;
}

.primary-button {
  background-color: #1890ff;
  border-color: #1890ff;
  color: #ffffff;
}

.secondary-button {
  background-color: #f0f2f5;
  border-color: #f0f2f5;
}

.danger-button {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  color: #ffffff;
}

.button-spacing {
  margin-right: 10px;
}

.button-alignment {
  padding-left: 40px;
}

.filter-button {
  background-color: rgb(240, 240, 240) !important;
  border-color: rgb(108, 117, 125) !important;
  color: rgb(108, 117, 125) !important;
  border-radius: 2px;
}

.btnApptm {
  justify-content: end !important;
  padding: 1% 0% 1% 0% !important;
}

.btnCRMConfirm {
  background-color: #096b09 !important;
  color: #ffffff !important;
  border-color: #096b09 !important;
  font-weight: 500 !important;
}

.btnCRMConfirm:hover {
  background-color: #ffffff !important;
  color: #098609 !important;
  border-color: #098609 !important;
  font-weight: 500 !important;
}

.btnCRMCancel {
  background-color: #c50c0c !important;
  color: #ffffff !important;
  border-color: #c50c0c !important;
  font-weight: 500 !important;
}

.btnCRMCancel:hover {
  background-color: #ffffff !important;
  color: #c50c0c !important;
  border-color: #c50c0c !important;
  font-weight: 500 !important;
}

.iconCRM {
  margin-right: 5px !important;
  font-size: 1.2em !important;
}

.security-appointment {
  text-align: center;
}

.image-size{
  height:100px;
}
.secutity-header{
  text-align: center;
}

.centered-footer .ant-modal-footer {
  display: flex;
  justify-content: center;
}

.skeleton-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(75, 72, 72, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
