.table-container {
  padding: 10px;
  max-Height: calc(90vh + 1rem);
  overflow: 'hidden';
  padding-top: 7%;
}


/* userTable.css */



.create-user-button {
  margin-left: 10px;
}

/* .table-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tablesearch{
   width: 200;
}

.CSV-actions {
  display: flex;
  justify-content: flex-end;
} */



.model-head {
  text-align: center;
}



.tablesearch {
  margin-left: auto;
}

.CSV-actions {
  display: flex;
  justify-content: flex-end;
}

.model-head {
  text-align: center;
}

:where(.css-dev-only-do-not-override-9b5bk7).ant-modal {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  pointer-events: none;
  position: relative;
  top: 15px;
  width: auto;
  max-width: calc(100vw - 32px);
  padding-bottom: 24px;
}

.filter-button {
  padding-left: 20px;
}

/* .custom-drawer .ant-drawer-body {
  padding: 0px !important;

} */
/* .custom-drawer .ant-drawer-body {
  padding: 0px 45px 0px 45px !important;
} */
.ant-drawer-body {

  background-color: #ffffff !important;
}

.ant-drawer-content {
  background-color: #f1f1f1 !important;
}

/* .divTable{
  padding: 8000px !important;
} */


.ant-table-tbody>tr>td {
  padding: 0px 8px !important;
}


.Buttons{
  display: flex;
  justify-content: flex-end;
}
.btnCancel{
  padding: 10px !important;
}
.btnDelete{
  padding: 10px !important;
}
