.ant-form-item-control-input {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 0px !important;
}


.ant-form-item-label {
    text-align: left;
}

@media (max-width: 768px) {
    .ant-form-item {
        margin-bottom: 16px;
    }

    .ant-col-8 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}


.Header-Style {
    position: fixed;
    width: 100%;
    z-index: 999999999;
    background-color: white;
    text-align: center;
}
.Header-Text {
    font-size: 1.6rem;
    font-weight: 700;
}
.ant-table-wrapper .ant-table-title {
    padding: 0px !important;
}   


.bordered-col {
    border: 1px solid #ddd;
    padding: 0px 8px;
  }

.borderNone{
    border-bottom:hidden !important ;
    border-top:hidden !important ;
    /* border:none !important */
    /* ; */
}
/* .borderhide{
    border: hidden !important;
} */
.custom-table-header {
   
    font-weight: 620;
    color: #555050;
    margin-bottom: 8px; /* Adjust as needed */
    padding: 9px 9px 0px 9px;

  }
  .custom-table-sideheader{
   
    font-weight:400;
    color: #555050;
    margin-bottom: 8px;
  }
  /* .vertical-divider {
    border-left: 1px solid #d9d9d9;
    height: 100%;
    position: relative;
    left: 50%;
} */


/* new try */

/* Ensures background color is applied only to .ant-form-item-label within .custom-table-header */
.ant-form-item-label:has(label .custom-table-header) {
    background-color: #fafafa !important;
    
}
.ant-form-item-control-input:has(label .custom-table-header) {
    padding-top: 10px !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
}
/* .custom-table-header .ant-form-item-control {
    padding-top: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
} */
