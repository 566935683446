.insuranceFrontImageDiv, .insuranceBackImageDiv,.secondaryInsuranceFrontImageDiv,
.secondaryInsuranceBackImageDiv,.licenseFrontImageDiv, .licenseBackImageDiv{
    background-size: 100%;
    background-repeat: no-repeat;
    height: 264px;
    width: 100%;
    max-width: 380px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    border: 1px solid grey;
    background-color: lightgray;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
}
