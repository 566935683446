.practice-content {
  display: flex;
  flex-direction: column;
  /* height: 65vh; */
  height: calc(100vh - 240px);
  flex-grow: 1;
  border: 1px solid #cccccc7c;
  border-radius: 5px;
  padding: 10px;
}

.button-footer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding-top: 10px;
}

.practice-content_sub
{
  display: flex;
  flex-direction: column;
  /*height: calc(100vh - 292px); */
  flex-grow: 1;
  border: 1px solid #cccccc7c;
  border-radius: 5px;
  padding: 10px;
}

.practice-content_advance
{
  display: flex;
  flex-direction: column;
  height: calc(100vh - 255px);
  flex-grow: 1;
  border: 1px solid #cccccc7c;
  border-radius: 5px;
}

.advance-config-div{
  border-left: 1px solid #e3e3e3;
  padding: 15px 15px;
  height: calc(100vh - 255px);
}

.advance-config-comp-body{
  height: calc(100vh - 320px);
}