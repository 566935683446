.fc-event-time {
    visibility: hidden;
}

.fc-direction-ltr .fc-daygrid-event .fc-event-time {
    margin-right: -29px;
}

.schedule-container {
    border-radius: 8px;
}

.TestCalc>.fc-header-toolbar {
    visibility: visible;

}

.schedule-body {
    background-color: #ffffff !important;
    padding: 20px !important;
    border-radius: 8px !important;
    border: 1px solid #d9d9d9 !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
    /* margin-bottom: 20px !important; */
}

#scheduleAppointment {
    background-color: #1890ff;
    border-color: #1890ff;
    color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1)
}

.TestCalc {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
/* calendra */

.fc-popover-body{
    max-height: 200px !important;
    overflow: auto !important;
}
.fc-h-event .fc-event-title-container{
    max-width: 100% !important;
}
.fc-daygrid-block-event .fc-event-time, .fc-daygrid-block-event .fc-event-title {
        padding: 2px 42px 5px 0px !important;
}
.fc .fc-daygrid-event-harness {
   
    padding: 5px !important;
}

.drpmonth{
    top: 55px !important;
    width: 150px !important;
    left:23px !important;
}
.fc-toolbar-chunk{
    padding-left: 160px !important;
}

.fc-today-button{
    width: 100px;
    height: 32px;
}
