
.btnMinusAdjust{
margin-right: 0% !important;
background-color: #f1f0f0 !important;
font-weight: bold !important;
}

.btnPlusAdjust{
margin-left: 0% !important;
background-color: #f1f0f0 !important;
font-weight: bold !important;
}

.inputTimerAdjust{
width: calc(100% - 80px)  !important;
height: 32px !important;
}


.ant-form-item-control {
    position: relative;
  }
  

  .button-container {
    display: flex;
    justify-content: flex-end;
    /* margin-top: 20px;  */
    gap: 10px;
}
