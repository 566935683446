.link-button {
    padding: 5px;
}

.btnupdate {
    padding: 5px;
}

.form-item-margin {
    margin-bottom: 5px;
}

.profile-header {
    text-align: center;
}

.editprofile-ccontent {
    display: 'flex' !important;
    justify-content: 'center' !important;
    align-items: 'center' !important;
    height: '100vh' !important;

}

.ant-modal-content {
    overflow-y: auto !important;
    max-height: calc(125vh - 200px) !important;
}


/* editProfile.css */
.button-container-profile {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.button-container-user {
    display: flex;
    justify-content: flex-end;
    /* margin-top: 20px;  */
    gap: 10px;
}


.link-button {
    margin-right: 20px;
}


:where(.css-dev-only-do-not-override-9b5bk7).ant-switch.ant-switch-checked .ant-switch-inner {
    padding-inline-start: 9px;
    padding-inline-end: 24px;
    background-color: green !important;
}

:where(.css-dev-only-do-not-override-9b5bk7).ant-switch .ant-switch-inner {
    display: block;
    overflow: hidden;
    border-radius: 100px;
    height: 100%;
    padding-inline-start: 24px;
    padding-inline-end: 9px;
    transition: padding-inline-start 0.2s ease-in-out, padding-inline-end 0.2s ease-in-out;
    background-color: red;
}

/* .form-container{
padding-top: 30px !important;
} */

/* .password{
    padding: 10px;
} */
.txtresetpassword {
    width: 100%;
}

.Buttons {
    display: flex;
    justify-content: flex-end;
}

.btncancel {
    padding: 10px !important;
}

.btnreset {
    padding: 10px !important;
}




