

.date-pickers-row {
    margin-top: 5px;
}

.date-picker {
    margin-bottom: 10px;
}

.testdata-switch {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.bold-text {
    font-weight: bold;
}

.execute-button {
    margin-top: 10px;
}

.report-data-container {
    margin-top: 20px;
}
.switchData{
    padding-left: 10px;
    padding-top:10px;
}