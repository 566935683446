/* .background-container-color {
    margin-top: 8%;

} */
.ant-drawer .ant-drawer-body {
    flex: 1 !important;
    min-width: 0 !important;
    min-height: 0 !important;
    /* padding: 0px 60px 0px 60px !important; */
    overflow: auto !important;
}

/* Input Box bellow unwanted space remove */
.ant-form-item {
    margin-bottom: 12px !important;
}

/* antd TABLE Overwrite */
/* .ant-table{
    height: calc(100vh - 180px) !important;
} */
.ant-table-body {
    overflow: auto !important;
    height: calc(100vh - 360px) !important;
    max-height: none !important;
}

.ant-pagination {
    margin: 10px 0 !important;
}

.ant-page-header {
    padding-block-start: 0px !important;
    padding-block-end: 0px !important;
    padding-inline-start: 21px !important;
}

/* diabled time picker color change */
.ant-picker .ant-picker-input>input[disabled] {
    background: transparent !important;
    color: #000000a8 !important;
    cursor: not-allowed !important;
}

/* diabled Update Button color change */
.ant-btn-primary:disabled,
.ant-btn-primary.ant-btn-disabled {
    cursor: not-allowed !important;
    border-color: #d9d9d9 !important;
    color: black !important;
    background: rgba(0, 0, 0, 0.04) !important;
    box-shadow: none !important;
}

/* diabled Cancel color change */
.ant-btn-default:disabled,
.ant-btn-default.ant-btn-disabled {
    cursor: not-allowed !important;
    border-color: #d9d9d9 !important;
    color: black !important;
    background: rgba(0, 0, 0, 0.04) !important;
    box-shadow: none !important;
}

/* for register page button position */
.ant-row-space-around {
    position: absolute !important;
    bottom: 0 !important;
    width: 380px !important;
}

/*Layout*/
.layout-container {
    max-height: calc(100vh - 89px);
    overflow: auto;
}

.ant-pro-card {
    border: 1px solid #d9d9d9 !important;
    border-radius: 12px;
}

/* grid  button color */
.gridIconDelete {
    background: #ff4d4f00 !important;
    color: #ff4d4f !important;
    box-shadow: none !important;
    /* padding: 1px !important;
    height: 25px !important;
    width: 25px !important; */
}

.gridIconEdit {
    color: #1677ff !important;
    background: #1677ff00 !important;
    box-shadow: none !important;
    /* padding: 1px !important;
    height: 25px !important;
    width: 25px !important; */
}

.gridIconEdit:hover {
    color: #ffffff !important;
    background: #1677ff !important;
}


.gridIconView {
    color: #1cb52e !important;
    background: #1677ff00 !important;
    box-shadow: none !important;
    /* padding: 1px !important;
    height: 25px !important;
    width: 25px !important; */
}

.gridIconView:hover {
    color: #ffffff !important;
    background: #1cb52e !important;
}

.gridIconDelete:hover {
    background: #ff4d4f !important;
    color: #ffffff !important;
}

/* menu active button color */
.ant-menu-light .ant-menu-item-selected,
.ant-menu-light>.ant-menu .ant-menu-item-selected {
    background-color: rgb(0 0 0 / 15%) !important;
}

/* content bg color */
/* .my-prefix-layout .my-prefix-layout-content {
    background-color: #00000012 !important;
    height: 100vh;
} */
.my-prefix-layout {
    height: 100vh;
    /* Set the container height to fill the viewport */
    background-color: #00000012;
    /* Your background color */
}

.my-prefix-layout-content {
    min-height: calc(100vh - 322px);
    /* Set minimum height to fill viewport minus other elements */
    overflow-y: auto;
    /* Enable vertical scrolling when content exceeds viewport height */
    /* Other styles for your content */
}

.my-prefix-sider-collapsed-button {

    color: rgb(0 0 0) !important;
    background-color: #c3c3c3 !important;
}

.custom-drawer .ant-drawer-body {
    /* padding: 15px 45px 0px 45px !important; */
}

/* .buttonLeft{
    padding-left:35% !important;
}
.buttonRight{
    padding-left:50% !important;
}
.button-container buttonBG{
    display: flex;
} */

.menu-item-custom {
    display: flex !important;
    align-items: center !important;
}

.menu-item-custom .anticon {
    margin-right: 8px !important;
}

.table-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.csv-download-button {
    font-size: 1.6em;
    justify-content: end;
}

.ant-modal-close {
    top: 14px !important;
}

.ant-divider-horizontal {
    margin: 15px 0px !important;
}

.ant-modal-content {
    padding: 0px 0px !important
}

.background-container-color {
    /* padding: 0px 20px 20px !important; */
}

.ant-modal-title {
    padding: 13px 1px 2px 19px !important;
    /* background-color: #f1f1f1; */
    /* border-bottom: 1px solid rgba(5, 5, 5, 0.06); */
}

.ant-spin-container {
    /* padding:13px !important; */
}

.ant-modal-body {
    padding: 0px 15px 5px 15px !important;
}

.form-container {
    height: calc(100vh - 123px) !important;
}

.container-height {
    height: calc(100vh - 143px) !important;
}

.rightAlignButtons {
    position: absolute;
    right: 0px;
    bottom: 0px !important;
}

.rightAlignButtons>div {
    display: inline-block;
    margin-right: 8px;
}

.ant-drawer .ant-drawer-body {
    padding: 11px !important;
}

.ant-modal-confirm-body {
    padding: 5px;
}

.guest-logo {
    text-align: center !important;
    margin: 0 !important;
    padding: 0 !important;
}

.even-row {
    background-color: #ffffff;
}

.odd-row {
    background-color: #fafafa;
}

.ant-drawer-body {
    padding: 10px !important;
}


.custom-row-class {
    height: 35px !important;
}

.ant-tabs-nav {
    margin-bottom: 5px !important;
}

.w-100 {
    width: 100% !important;
}

.fieldset {
    border: 1px solid #e4e4e4;
    border-radius: 5px;
}

.form-group {
    margin-bottom: 0px;
}

.w-75 {
    width: 75% !important;
}

.w-50 {
    width: 50% !important;
}

.ant-modal-footer {
    padding: 0px 10px 16px 5px !important;
}

.deletedynamicName {
    color: red;
    padding-left: 5px !important;
}

.ant-modal-confirm-btns {
    padding: 0px 10px 10px 10px !important;
}

.ant-collapse-header {
    font-weight: bolder;
}

.ant-popover>colorPicker {
    inset: 203px auto auto 400px !important;
}

.fc-header-toolbar {
    visibility: hidden;
}



.custom-red {
    background-color: red;
}

.textAreaField {
    height: 13vh !important;
}

.ant-input-disabled {
    color: #000000a8 !important;
}

.practice-default-container {
    padding: 20px;
    background: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.practice-content {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.button-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.button-footer .ant-btn {
    margin-right: 8px;
}

.form-item {
    border: 1px solid #e8e8e8;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
}

.ant-pro-card {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    margin-block: 0;
    margin-inline: 0;
    padding-block: 0;
    padding-inline: 0;
    background-color: #f3f3f3 !important;
    border-radius: 6px;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
}

.ant-layout-header {
    height: 46px;
}

.fc-event-time {
    margin-right: -56px !important;
}

.ant-picker {
    width: 100% !important;
}

.ant-picker .ant-picker-input {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 313px;
}

.blockFooterButton {
    gap: 10px;
}

.buttongap {
    padding: 10px;
}
