.form-item-margin{
    margin-bottom: 3px;
}
.btnsubmit {
  color: white; /* Set text color to white */
}

.btnsubmit:disabled {
  color: #fff;
}

.background-container{
    position: fixed;
    width: 100% !important;
}
.set-password-card{
padding-left: 3%;
padding-right: 3%;
}
.guest-box-msg {
    font-size: 18px; /* Increase the font size */
    font-weight: bold; /* Make the text bold */
    padding-bottom: 0px;
  }

@media (max-width: 640px) { 
 .divsetpasswordcontent {
    display: block !important;
    min-height: '100vh';  
  }
  .set-password-card{
    padding-left: 3%;
    padding-right: 3%;
    }
}
